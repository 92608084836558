import { useState, useMemo } from "react";

function useShowMore({ places }) {
  const [isListSizeRestricted, setListSizeRestricted] = useState(true);

  const visiblePlaces = useMemo(() => {
    if (!isListSizeRestricted) {
      return places;
    }

    return places.slice(0, 20);
  }, [places, isListSizeRestricted]);

  const hiddenBusinessCount = places.length - visiblePlaces.length;

  return {
    visiblePlaces,
    hasMore: hiddenBusinessCount > 0,
    showMore: () => setListSizeRestricted(false),
    moreCount: hiddenBusinessCount,
  };
}

export default useShowMore;
