import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useContext, useMemo } from "react";
import { css } from "styled-components";

import FilterContext from "../context/FilterContext";
import useShowMore from "../hooks/useShowMore";
import { filterPlaces, addPlaceDistances } from "../util/places";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BackToTop from "../components/BackToTop";
import Places from "../components/Places";
import Filter from "../components/Filter";

const pageStyles = css`
  .placesGrid {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 60px 20px;
    margin-top: 40px;
  }

  .loadMore {
    margin-top: 40px;
  }

  @media all and (min-width: 800px) {
    .placesGrid {
      grid-template-columns: repeat(2, minmax(350px, 1fr));

      .placeWrapper {
        max-width: 500px;
      }
    }
  }
`;

const IndexPage = () => {
  const {
    currentTags,
    userPosition,
    isHidingClosedLocations,
    distanceRestriction,
  } = useContext(FilterContext);

  const { allPlace } = useStaticQuery(graphql`
    query allPlace {
      allPlace {
        nodes {
          ...placeFragment
        }
      }
    }
  `);

  const placesWithDistance = useMemo(
    () => addPlaceDistances({ places: allPlace.nodes, userPosition }),
    [allPlace.nodes, userPosition]
  );

  const filteredPlaces = useMemo(
    () =>
      filterPlaces({
        currentTags,
        distanceRestriction,
        isHidingClosedLocations,
        places: placesWithDistance,
      }),
    [
      currentTags,
      distanceRestriction,
      isHidingClosedLocations,
      placesWithDistance,
    ]
  );

  const { moreCount, visiblePlaces, hasMore, showMore } = useShowMore({
    places: filteredPlaces,
  });

  return (
    <Layout>
      <SEO title="Hamilton Locations" />
      <div id="top" css={pageStyles}>
        <Filter places={filteredPlaces} />

        <section>
          <h1>Hamilton Restaurant List</h1>

          <p>
            Own a restaurant? Your favourite spot missing from the list?{" "}
            <Link to="/new-business">Submissions are welcome</Link>.
            <br />
            Have any feedback or suggestions?{" "}
            <Link to="/feedback">Get in touch</Link>.
          </p>

          <Places places={visiblePlaces} currentTags={currentTags}></Places>

          {hasMore && (
            <p className="loadMore">
              The list doesn't end here! {moreCount} more businesses are
              currently hidden.{" "}
              <button className="textButton" onClick={showMore}>
                Click here
              </button>{" "}
              to view more.
            </p>
          )}
        </section>

        <BackToTop></BackToTop>
      </div>
    </Layout>
  );
};

export default IndexPage;
