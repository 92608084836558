import React from "react";
import styled from "styled-components";
import { useScrollPosition } from "../hooks/useScrollPosition";

const BackToTopLink = styled.a`
  position: fixed;
  bottom: 1%;
  right: 1%;
  background: var(--pink);
  color: white;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 3px;
  padding: 10px 15px;
  transition: opacity 0.2s;
  opacity: 0;
  text-decoration: none;
  ${props =>
    props.percent > 0.25 &&
    `
    opacity: 1;
  `}
`;

export default function BackToTop() {
  const percent = useScrollPosition();

  return (
    <BackToTopLink href="#top" percent={percent}>
      Back to top
    </BackToTopLink>
  );
}
